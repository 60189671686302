import styled from 'styled-components';
import {grid, space, layout, color, typography} from 'styled-system';
// TODO: Add breakpoints
//
const Box = styled.div`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${grid}
`;

export default Box;

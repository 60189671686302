import * as React from 'react';

const X = () => {
  return (
    <svg width={19} height={19} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line
        x1="1.31549"
        y1="1.15912"
        x2="18.3155"
        y2="18.1591"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <line
        x1="1.46967"
        y1="18.1591"
        x2="18.4697"
        y2="1.15912"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default X;

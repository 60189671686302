import * as React from 'react';
import styled from 'styled-components';
import {flexbox} from 'styled-system';

import Box from '../components/Box';

const Flex = styled(Box)`
  display: flex;
  ${flexbox}
`;

export default Flex;

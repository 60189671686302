import * as React from 'react';

const Hamburger = () => {
  return (
    <svg viewBox="0 0 100 80" width="40" height="40" fill="white" stroke="black">
      <rect width="100" height="10"></rect>
      <rect y="30" width="100" height="10"></rect>
      <rect y="60" width="100" height="10"></rect>
    </svg>
  );
};

export default Hamburger;

import * as React from 'react';
import styled from 'styled-components';

import Box from '../components/Box';

// styles
const Icon = styled(Box).attrs({
  as: 'span',
  fontSize: ['10px', null, '20px'],
})``;

const Heart = () => {
  return <Icon>&#10084;</Icon>;
};

export default Heart;

import * as React from 'react';
import styled from 'styled-components';

import Box from '../components/Box';
import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';

// styles
const Content = styled(Box).attrs({
  mb: ['20px', null, null, '40px'],
  fontSize: ['40px', '50px', null, '60px', '70px'],
})`
  font-family: 'Sacramento', cursive;
  text-align: center;
`;

const Title = ({text}) => {
  return <Content>{text}</Content>;
};

export default Title;

import * as React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {AnchorLink} from 'gatsby-plugin-anchor-links';

import Box from '../components/Box';
import Flex from '../components/Flex';
import Heart from '../components/Heart';
import Hamburger from '../components/Hamburger';
import X from '../components/X';

import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';

// styles
const DesktopWrapper = styled(Box).attrs({
  p: ['none', null, null, null, '30px 40px', '30px 80px'],
  display: ['none', null, null, null, 'flex'],
})`
  list-style-type: none;
  background-color: ${props => (props.hideNavBackground ? '' : '#2B3147')};
  position: sticky;
  top: 0;
  box-shadow: ${props =>
    props.hideNavBackground ? '' : '0 4px 18px 0px rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%)'};
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;
const MobileWrapper = styled(Box).attrs({
  display: ['flex', null, null, null, 'none'],
  p: '20px',
})`
  background-color: ${props => (props.hideNavBackground ? '' : '#2B3147')};
  box-shadow: ${props =>
    props.hideNavBackground ? '' : '0 4px 18px 0px rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%)'};
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const StyledLink = styled(Link)`
  font-family: 'Open Sans';
  font-size: 18px;
  cursor: pointer;
  color: ${props => (!!props.renderblack ? 'black' : 'white')};
  margin-top: ${props => (!!props.renderblack ? '20px' : '')};
  text-decoration: none;
  padding-left: 20px;
  :hover {
    color: #d0d0d0;
  }
`;

const StyledAnchorLink = styled(AnchorLink)`
  font-family: 'Open Sans';
  font-size: 18px;
  cursor: pointer;
  color: ${props => (!!props.renderblack ? 'black' : 'white')};
  margin-top: ${props => (!!props.renderblack ? '20px' : '')};
  text-decoration: none;
  padding-left: 20px;
  :hover {
    color: #d0d0d0;
  }
`;

const LogoWrapper = styled(Link)`
  cursor: pointer;
  font-family: 'Sacramento', cursive;
  color: ${props => (!!props.renderblack ? 'black' : 'white')};
  text-decoration: none;
  text-align: center;
`;

const Date = styled(Box).attrs({
  fontSize: ['10px', null, '15px', null, null, '25px'],
})`
  font-family: 'Shadows Into Light', cursive;
`;

const Toggle = styled(Box)`
  height: 100%;
  cursor: pointer;
  z-index: 10;
`;

const Navbox = styled(Box).attrs({})`
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #fff;
  transition: all 0.3s ease-in;
  left: ${props => (props.open ? '-100%' : '0')};
  z-index: 1000;
`;

const Links = ({renderblack = null, onClickNavBar = () => {}}) => (
  <>
    <StyledAnchorLink
      to="/#our-story"
      renderblack={renderblack}
      onAnchorLinkClick={onClickNavBar}
      gatsbyLinkProps={{activeStyle: {color: '#9E9C91'}}}
    >
      Our Story
    </StyledAnchorLink>
    <StyledAnchorLink
      to="/#details"
      renderblack={renderblack}
      onAnchorLinkClick={onClickNavBar}
      activeStyle={{color: '#9E9C91'}}
    >
      The Details
    </StyledAnchorLink>
    <StyledAnchorLink
      to="/#getting-there"
      renderblack={renderblack}
      onAnchorLinkClick={onClickNavBar}
      activeStyle={{color: '#9E9C91'}}
    >
      Getting There
    </StyledAnchorLink>
    <StyledAnchorLink
      to="/#where-to-stay"
      renderblack={renderblack}
      onAnchorLinkClick={onClickNavBar}
      activeStyle={{color: '#9E9C91'}}
    >
      Where To Stay
    </StyledAnchorLink>
    <StyledAnchorLink
      to="/#things-to-do"
      renderblack={renderblack}
      onAnchorLinkClick={onClickNavBar}
      activeStyle={{color: '#9E9C91'}}
    >
      Things To Do
    </StyledAnchorLink>

    <StyledAnchorLink
      to="/#registry"
      renderblack={renderblack}
      onAnchorLinkClick={onClickNavBar}
      activeStyle={{color: '#9E9C91'}}
    >
      Registry
    </StyledAnchorLink>

    <StyledAnchorLink
      to="/#faq"
      renderblack={renderblack}
      onAnchorLinkClick={onClickNavBar}
      activeStyle={{color: '#9E9C91'}}
    >
      FAQ
    </StyledAnchorLink>
    <StyledLink to="/photos" renderblack={renderblack} activeStyle={{color: '#9E9C91'}}>
      Photos
    </StyledLink>
  </>
);

const Logo = ({renderBlack = null}) => (
  <LogoWrapper to="/" renderblack={renderBlack} activeStyle={{cursor: 'default'}}>
    <Box fontSize={['25px', null, '35px', null, null, '55px']}>Erica & Alex</Box>
    <Date>
      3 <Heart /> 26 <Heart /> 2022
    </Date>
  </LogoWrapper>
);

const MobileNavHeader = ({navbarOpen, onClickNavBar}) => (
  <Flex p={'20px'} display={'flex'} justifyContent={'space-between'}>
    <Logo renderBlack={'true'} />
    <Toggle navbarOpen={navbarOpen} onClick={onClickNavBar}>
      <X />
    </Toggle>
  </Flex>
);

const NavBar = ({hideNavBackground}) => {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const onClickNavBar = () => setNavbarOpen(!navbarOpen);

  return (
    <>
      <DesktopWrapper hideNavBackground={hideNavBackground}>
        <Logo />
        <Box>
          <Links />
        </Box>
      </DesktopWrapper>
      <MobileWrapper hideNavBackground={hideNavBackground}>
        <Logo />
        <Toggle navbarOpen={navbarOpen} onClick={onClickNavBar}>
          {navbarOpen ? '' : <Hamburger />}
        </Toggle>
      </MobileWrapper>
      {navbarOpen ? (
        <Navbox>
          <MobileNavHeader navbarOpen={navbarOpen} onClickNavBar={onClickNavBar} />
          <Flex flexDirection={'column'}>
            <Links renderblack={'true'} onClickNavBar={onClickNavBar} />
          </Flex>
        </Navbox>
      ) : (
        <Navbox open>
          <MobileNavHeader navbarOpen={navbarOpen} onClickNavBar={onClickNavBar} />
        </Navbox>
      )}
    </>
  );
};

export default NavBar;
